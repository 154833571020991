import React from "react";
import "./profile.css";
import resume from "../../../assets/resume.pdf";
import { ImLinkedin2 } from "react-icons/im";
import { BsGithub } from "react-icons/bs";
import { FaCodepen } from "react-icons/fa";
const profile = () => {
    return (
        <div className="profile secondary-bg primary-color">
            <div className="profile-body">
                <img
                    className="profile-img"
                    src="https://avatars.githubusercontent.com/u/79480788?v=4"
                    alt=""
                />
                <h2 className="profile-name">Maruf Marzuq</h2>
                <p className="profile-title">Software Engineer</p>
                <div className="profile-social">
                    <a
                        target="_blank"
                        href="https://github.com/marufmarzuq"
                        rel="noreferrer"
                    >
                        <BsGithub />
                    </a>
                    <a
                        target="_blank"
                        href="https://www.linkedin.com/in/marufmarzuq"
                        rel="noreferrer"
                    >
                        <ImLinkedin2 />
                    </a>
                    <a
                        target="_blank"
                        href="https://codepen.io/marufmarzuq"
                        rel="noreferrer"
                    >
                        <FaCodepen />
                    </a>
                </div>
                <a
                    target="_blank"
                    className="resume-btn"
                    href={resume}
                    rel="noreferrer"
                >
                    Resume
                </a>
            </div>
            <div className="profile-footer">Last updated on 20/02/2022</div>
        </div>
    );
};

export default profile;
