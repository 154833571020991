import React, { useState } from "react";
import "./about.css";
import img from "../../assets/images/unknown.jpg";
import { MdOutlineDevices } from "react-icons/md";
import { HiOutlineCode } from "react-icons/hi";
import { BsWordpress } from "react-icons/bs";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { motion } from "framer-motion";
const About = () => {
    const skills = [
        {
            title: "JavaScript",
            year: 3,
            project: 14,
        },
        {
            title: "TypeScript",
            year: 2,
            project: 7,
        },
        {
            title: "React Js",
            year: 3,
            project: 12,
        },
        {
            title: "Gatsby",
            year: 2,
            project: 2,
        },
        {
            title: "Node Js",
            year: 2,
            project: 14,
        },
        {
            title: "MongoDB",
            year: 3,
            project: 10,
        },
        {
            title: "PostgreSQL",
            year: 3,
            project: 7,
        },
        {
            title: "Material UI",
            year: 2,
            project: 6,
        },
    ];
    const [sliderIndex, setSliderIndex] = useState(0);
    const handleArrow = (arrow) => {
        if (arrow === "left") {
            setSliderIndex(sliderIndex - 1);
        } else {
            setSliderIndex(sliderIndex + 1);
        }
    };
    return (
        <motion.section layout className="about primary-bg primary-color">
            <div className="page-container">
                <h2 className="page-title">
                    About <span className="colorized">Me</span>
                </h2>
                <div className="about-container">
                    <div className="a-text">
                        <p>
                            Hi, I'm Maruf Marzuq - a full stack web developer
                            from Bangladesh. I’ve a deep interest in JavaScript,
                            sticking up for learning and developing myself every
                            day. I always focus on learning new technology.
                        </p>
                        <p>
                            I believe web development is the field where I can
                            show my creativity by best use of my merit and
                            skills. When I'm not coding, you can find me geeking
                            about photography, trying new food, and exploring
                            different ways of creating content that can help
                            people pivot their tech careers.
                        </p>
                        <p>
                            I'm happiest when I'm creating, learning, exploring
                            and thinking about how to make things
                            better.Currently learning data structures and
                            algorithms and solving problems at HackerRank. I'm
                            available for any freelance work, feel free to reach
                            out and say hello!
                        </p>
                    </div>
                    <div className="a-address">
                        <p>
                            <span className="colorized">Age</span> 25
                        </p>
                        <p>
                            <span className="colorized">Residence</span>{" "}
                            Bangladesh
                        </p>
                        <p>
                            <span className="colorized">Address</span>{" "}
                            Mohakhali, Dhaka
                        </p>
                        <p>
                            <span className="colorized">E-mail</span>{" "}
                            marufmarzuq18@gmail.com
                        </p>
                        <p>
                            <span className="colorized">Phone</span> +880
                            1760265926
                        </p>
                    </div>
                </div>
                <div className="edu-skill">
                    <div>
                        <div className="education">
                            <h3 className="sub-title">Education</h3>
                            <div className="ee-box">
                                <div className="ee-box-left">
                                    <h4>Ongoin</h4>
                                    <h5>Southeast University</h5>
                                </div>

                                <div className="ee-box-right">
                                    <h3>B.Sc. in CSE</h3>
                                    <p>
                                        I'm currently pursuing a degree in
                                        Computer Science and Engineering at
                                        Southeast University in Dhaka. I have a
                                        strong passion for mathematics and enjoy
                                        delving into data structures and
                                        algorithms to solve complex problems.
                                    </p>
                                </div>
                            </div>

                            <div className="ee-box">
                                <div className="ee-box-left">
                                    <h4>2020</h4>
                                    <h5>Udemy</h5>
                                </div>

                                <div className="ee-box-right">
                                    <h3>
                                        The Complete JavaScript Course | From
                                        Zero to Expert!
                                    </h3>
                                    <p>
                                        From this course I learned basic to
                                        advance JavaScript and use of
                                        JavaScript. Learned JavaScript in depth
                                        from this course.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="education">
                            <h3 className="sub-title">Experience</h3>
                            <div className="ee-box">
                                <div className="ee-box-left">
                                    <h4>2024 - Current</h4>
                                    <h5>Fringecore</h5>
                                </div>

                                <div className="ee-box-right">
                                    <h3>Software Engineer</h3>
                                    <p>
                                        - Developing and maintaining a
                                        large-scale digital signage system for
                                        over 1000 screens
                                        <br />- Working with 3D models using
                                        Three.js for interactive visualizations
                                        <br />- Implementing computer vision AI
                                        solutions for footfall counting and
                                        demography detection
                                    </p>
                                    <p>
                                        <strong>Products:</strong> Citybank Cube
                                        Controller, AD-IQ CMS, AD-IQ Client
                                        Portal
                                    </p>
                                </div>
                            </div>
                            <div className="ee-box">
                                <div className="ee-box-left">
                                    <h4>2023</h4>
                                    <h5>SoftValley</h5>
                                </div>

                                <div className="ee-box-right">
                                    <h3>Software Developer</h3>
                                    <p>
                                        - Worked on tech solutions for overseas
                                        university consultants.
                                        <br />- Developed two fully customized
                                        web applications from scratch.
                                    </p>
                                    <p>
                                        <strong>Products:</strong> CRM (Customer
                                        Relationship Management ), Apply Portal
                                    </p>
                                </div>
                            </div>
                            <div className="ee-box">
                                <div className="ee-box-left">
                                    <h4>2022</h4>
                                    <h5>Appstone Pvt. Ltd.</h5>
                                </div>

                                <div className="ee-box-right">
                                    <h3>Associate Software Developer</h3>
                                    <p>
                                        - Worked on tech solutions for medical
                                        needs and farm holders.
                                        <br />- Redesigned and developed several
                                        enterprise-level web applications
                                    </p>
                                    <p>
                                        <strong>Products:</strong> DrSignet web,
                                        Signet Admin Panel, Signet Pharma,
                                        Signet Laboratory, AQAI OMS, AQAI FMS
                                        (Farm Management System)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="skill">
                        <h3 className="sub-title">Coding Skills</h3>
                        <div className="skill-boxes">
                            {skills.map((skill) => (
                                <div className="single-skill-box">
                                    <div className="skill-box-left">
                                        <div className="skill-title">
                                            {skill.title}
                                        </div>
                                        <p>
                                            {skill.project} projects |{" "}
                                            <span>{skill.year} years</span>
                                        </p>
                                    </div>
                                    <div className="skill-level">
                                        {Array(skill.project)
                                            .fill(0)
                                            .map((n) => (
                                                <div className="project-level"></div>
                                            ))}
                                        {Array(skill.year)
                                            .fill(0)
                                            .map((n) => (
                                                <div className="year-level"></div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="what-ido ">
                    <h3 className="sub-title">
                        What <span>I Do</span>
                    </h3>
                    <div className="wi-boxes">
                        <div className="wi-box">
                            <MdOutlineDevices />

                            <h4>Front-end Development</h4>
                            <p>
                                I like to code things from scratch, and enjoy
                                bringing ideas to life in the browser.
                            </p>
                        </div>
                        <div className="wi-box">
                            <HiOutlineCode />
                            <h4>Back-end Development</h4>
                            <p>
                                I genuinely care about client, and love to give
                                my best to make the job perfect.
                            </p>
                        </div>
                        <div className="wi-box">
                            <BsWordpress />
                            <h4>Wordpress Theme Customization</h4>
                            <p>
                                I value simple content structure, clean design
                                patterns, and thoughtful interactions with this
                                powerful CMS.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="testimonial">
                    <div className="testimonial-header">
                        <h3 className="sub-title">Testimonials</h3>
                        <div className="testi-slider-btns">
                            <button
                                onClick={() => handleArrow("left")}
                                className={sliderIndex === 0 ? "disabled" : ""}
                            >
                                <AiOutlineLeft />
                            </button>
                            <button
                                onClick={() => handleArrow("right")}
                                className={sliderIndex === 2 ? "disabled" : ""}
                            >
                                <AiOutlineRight />
                            </button>
                        </div>
                    </div>
                    <div className="testi-slider-container">
                        <div
                            className="testi-boxes"
                            style={{
                                transform: `translateX(${sliderIndex * -100}%)`,
                            }}
                        >
                            <div className="testi-box">
                                <img
                                    src="https://media.licdn.com/dms/image/v2/D5603AQGNwluDaP_YzA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1707498468568?e=1743638400&v=beta&t=ZYvxzD7w_RT9hc4ss-7N-AVv_fcEx3bAgA7oj5hUvJs"
                                    alt=""
                                />
                                <p>
                                    I had the privilege of working with Maruf at
                                    Soft Valley and I can confidently say that
                                    he is a highly skilled front-end developer.
                                    Maruf has extensive experience with React.js
                                    and Next.js, and his understanding of
                                    front-end technologies is truly impressive.
                                </p>
                                <h4>Mehedi Ul Hasnain</h4>
                                <h5>Software Architect</h5>
                            </div>
                            <div className="testi-box">
                                <img
                                    src="https://media.licdn.com/dms/image/v2/D5635AQEExAnoO3VV3Q/profile-framedphoto-shrink_100_100/profile-framedphoto-shrink_100_100/0/1733429645865?e=1738742400&v=beta&t=b7czGRQEQeiPxF3WEBNNfa1vNM5zuysISo8ZxU9UN00"
                                    alt=""
                                />
                                <p>
                                    Maruf has always fascinates me with his
                                    eagerness to learn and problem solving
                                    nature. He is helpful and possesses skills
                                    of a good team player. Moreover his
                                    interpersonal and communication skills are
                                    brilliant. it's has been a pleasure and a
                                    learning journey for me while working with
                                    Maruf.
                                </p>
                                <h4>Masud Rana</h4>
                                <h5>UX Engineer</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default About;
