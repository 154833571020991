import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import resume from "../../../assets/resume.pdf";
import "./sideMenu.css";
import { GoHome } from "react-icons/go";
import { RiUser6Line } from "react-icons/ri";
import { AiOutlineCode } from "react-icons/ai";
import { GiNotebook } from "react-icons/gi";
import { RiSendPlaneLine } from "react-icons/ri";

const SideMenu = () => {
    const [sideMenuStatus, setSideMenuStatus] = useState(false);
    const [activeManu, setActiveMenu] = useState("");
    const { pathname } = useLocation();
    const firstPath = pathname.split("/")[1];
    const handleToggleMenu = () => {
        const status = sideMenuStatus;
        setSideMenuStatus(!status);
    };
    document.onclick = function (h) {
        if (h.target.id !== "toggle" && h.target.id !== "sidebar") {
            setSideMenuStatus(false);
        }
    };
    useEffect(() => {
        setActiveMenu(firstPath);
    }, [firstPath]);
    return (
        <>
            <div className="toggle-container">
                <div className="toggle-circle primary-bg">
                    <div
                        id="toggle"
                        onClick={handleToggleMenu}
                        className={
                            sideMenuStatus ? "active menu-icon" : "menu-icon"
                        }
                    ></div>
                </div>
            </div>
            <div
                className={
                    sideMenuStatus
                        ? "active side-menu primary-bg"
                        : "side-menu primary-bg"
                }
            >
                <div id="sidebar" className="scrollable-div">
                    <div className="side-menu-profile">
                        <img
                            className="sidebar-img"
                            src="https://avatars.githubusercontent.com/u/79480788?v=4"
                            alt=""
                        />
                        <h2 className="sidebar-name">Maruf Marzuq</h2>
                        <div className="profile-social">
                            <a
                                target="_blank"
                                href="https://github.com/marufmarzuq"
                                rel="noreferrer"
                            >
                                <i className="fab fa-github"></i>
                            </a>
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/in/marufmarzuq"
                                rel="noreferrer"
                            >
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                            <a
                                target="_blank"
                                href="https://codepen.io/marufmarzuq"
                                rel="noreferrer"
                            >
                                <i className="fab fa-codepen"></i>
                            </a>
                        </div>
                        <a
                            target="_blank"
                            className="resume-btn"
                            href={resume}
                            rel="noreferrer"
                        >
                            Resume
                        </a>
                    </div>
                    <nav>
                        <ul>
                            <li>
                                <Link
                                    to="/"
                                    className={
                                        activeManu === ""
                                            ? "active"
                                            : "not-active"
                                    }
                                >
                                    <GoHome />
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about"
                                    className={
                                        activeManu === "about"
                                            ? "active"
                                            : "not-active"
                                    }
                                >
                                    <RiUser6Line />
                                    <span>About Me</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/work"
                                    className={
                                        activeManu === "work"
                                            ? "active"
                                            : "not-active"
                                    }
                                >
                                    <AiOutlineCode />
                                    <span>Works</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/blog"
                                    className={
                                        activeManu === "blog"
                                            ? "active"
                                            : "not-active"
                                    }
                                >
                                    <GiNotebook />
                                    <span>Blogs</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact"
                                    className={
                                        activeManu === "contact"
                                            ? "active"
                                            : "not-active"
                                    }
                                >
                                    <RiSendPlaneLine />
                                    <span>Contact</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
};

export default SideMenu;
